<template>
    <div class="bg-black text-gray-100 z-10 text-sm">
        <div class="pt-24 mx-6 md:mx-16">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8  mb-24">
                <div class="">
                    <img src="@/assets/svg/logo-side.svg" class="w-auto h-16" alt="Noshtrybe">
                    <p class="mt-4" >71 CMD Road,By GPS School,<br>Close to Magodo Brooks Estate,<br>Lagos, Nigeria </p>
                </div>
                <div class="">
                    <h5 class="text-2xl font-semibold">Top Trybes</h5>
                <ul class="mt-4">
                    <li class="mt-2 hover:text-brand transition duration-100 ease-in">30BG GENG</li>
                    <li class="mt-2 hover:text-brand transition duration-100 ease-in">Wizkid FC</li>
                    <li class="mt-2 hover:text-brand transition duration-100 ease-in">Outsiders</li>
                    <li class="mt-2 hover:text-brand transition duration-100 ease-in">Ravers</li> 
                </ul>
                </div>
                <div>
                    <h5 class="text-2xl font-semibold">Get to know us</h5>
                    <ul class="mt-4">
                    <li class="mt-2"><a href="about" class=" hover:text-brand transition duration-100 ease-in">About us</a></li>
                    <li class="mt-2"><a href="contact" class="hover:text-brand transition duration-100 ease-in">Contact us</a></li>
                     <li class="mt-2"><a href="faqs"  class="hover:text-brand transition duration-100 ease-in">FAQs</a></li>
                    <li class="mt-2 hover:text-brand transition duration-100 ease-in">Blog</li> 
                    <li class="mt-2 hover:text-brand transition duration-100 ease-in">Terms and conditions</li> 
                </ul>
                </div>
                <div class="mx-3">
                    <h5 class="text-2xl font-semibold">Stay connected</h5>
                    <div class="flex justify-around space-x-2  mt-4 bg-gray-200 h-14 pl-4 rounded-full md:w-full">
                        <input type="text" name="delivery" class="bg-gray-200 text-black w-full rounded-full focus:outline-none" id="delivery" placeholder="Enter E-mail Address .." >
                        <div class="flex-grow flex justify-end">
                            <button class="bg-brand border-4 shadow-md border-white h-full w-14 flex justify-center outline-none items-center rounded-full" >
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 transform rotate-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-28 pb-8">
                <div class="h-0.5 bg-gray-200 w-full"></div>
                <div class="grid grid-cols-1 lg:grid-cols-3">
                    <div class="col-span-2" >
                        <p class="text-xs mt-2 text-center lg:text-left">Copyright © {{ year }} Noshtrybe (trading as SDS Africa Marketplace) | Delivery powered by <a href="https://dellyman.com" target="_blank" rel="noopener noreferrer">Dellyman</a></p>
                    </div>
                    <div class="flex space-x-1 items-center justify-end mt-1 mr-4">
                        <img src="@/assets/img/verve.png" class="h-4 w-auto" alt="card">
                        <img src="@/assets/img/mastercard.png" class="h-6 w-auto" alt="card">
                        <img src="@/assets/img/visa.png" class="h-6 w-auto" alt="card">
                    </div>
                </div>
            </div>     
        </div>
    </div>
</template>
<script>
import Modal from "@/components/Modal.vue";
export default {
    nameL:"Footer",
    components:{
        Modal
    },
    data(){
        return{
            expired:false,
            year : new Date().getFullYear() 
        }
    }
}
</script>

<style>

</style>