<template>
  <div ref="overlay" class="fixed z-50 h-full w-full  top-0 left-0 bg-overlay" @click.self="closeNav">
    <div v-if="showMain" ref="menu" class="h-screen md:w-96 w-9/12 bg-white transition duration-500 ease-in-out transform -translate-x-full">
        <div class="pt-3">
            <div class="mx-3">
                <div class="flex justify-end">
                    <div class="flex items-center"   @click="closeNav">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <ul class="mt-8 grid grid-rows-1 gap-4 text-sm" >
                    <li v-if="!loggedUser"  class="mt-4 font-semibold"><router-link :to="{ name:'CustomerSignIn'}" >Get started</router-link></li>
                    <li v-if="loggedUser">
                        <div>
                            <router-link :to="{ name: 'OrderHistory'}" :class="{ 'text-brand': this.$route.name == 'OrderHistory'}" class=" font-semibold hover:text-brand transition ease-in-out duration-300" >Order history</router-link>
                            <p class="text-xs text-gray-500">View your order history</p>
                        </div>
                    </li>
                    <li v-if="loggedUser">
                        <div>
                            <router-link :to="{ name: 'Reviews'}" :class="{ 'text-brand': this.$route.name == 'Reviews'}" class="font-semibold hover:text-brand transition ease-in-out duration-300" >Reviews</router-link>
                            <p class="text-xs text-gray-500">View your reviews </p>
                        </div>
                    </li>
                    <li v-if="loggedUser">
                        <div>
                             <router-link :to="{ name: 'WalletHistory'}" :class="{ 'text-brand': this.$route.name == 'WalletHistory'}" class="font-semibold hover:text-brand transition ease-in-out duration-300" >Wallet</router-link>
                            <p class="text-xs text-gray-500">Add money into your wallet</p>
                        </div>
                    </li>
                    <li v-if="loggedUser">
                        <div>
                            <router-link :to="{ name: 'Settings'}" :class="{ 'text-brand': this.$route.name == 'Settings'}" class="font-semibold hover:text-brand transition ease-in-out duration-300" >Account Settings</router-link>
                            <p class="text-xs text-gray-500">Edit your profile</p>
                        </div>
                    </li>
                    <li v-if="loggedUser" @click="logout()" class="font-semibold cursor-pointer">Logout</li>
                </ul>
            </div>
        </div>
    </div>
    <div ref="dashboard" class="h-full overflow-y-auto w-full bg-white transition duration-500 ease-in-out transform -translate-x-full">
        <div class="p-3" >
            <OrderHistory v-if="pages.history" :activeuser="activeuser" :token="loggedUser" @close="closeNav"/>
            <Reviews v-if="pages.review" :token="loggedUser"  @close="closeNav"/>
            <Settings v-if="pages.setting" :token="loggedUser" :activeuser="activeuser" @update="update" @close="closeNav" />
            <Wallet  v-if="pages.wallet" :activeuser="activeuser" :token="loggedUser" @updateWallet="update" @close="closeNav"/>
        </div>
    </div>
  </div>
</template>

<script>

import OrderHistory from "@/components/customers/OrderHistory";
import Reviews from "@/components/customers/Reviews";
import Settings from "@/components/customers/Settings";
import Wallet from "@/components/customers/Wallet";
export default {
    name:['MobileNav'],
    components:{
        OrderHistory,Reviews,Settings,Wallet
    },
     emits: ["updateWallet","closeNav"],
    props:['activeuser'],
    data(){
        return{
            loggedUser:this.$store.getters.getUser,
            showMain: true,
            pages:{
                wallet:false,
                setting:false,
                history:false,
                review:false
            }
        }
    },
    methods:{
        closeNav(){   
            if(this.$refs.menu){
                this.$refs.menu.classList.add('-translate-x-full')
            }else{
                document.querySelector('body').classList.remove("overflow-hidden")
            }              
            setTimeout(() => {
                    this.$emit('closeNav');    
            }, 500);
        },
        update(){
            this.$emit('updateWallet');
        },
        customerDashboard(to){
            // this.$refs.menu.classList.add('-translate-x-full');
            // alert("show")
            this.showMain = false;
            let pages = this.pages
            Object.keys(pages).map(function(key, index) {
                 if(key == to){
                    pages[key] = true;
                }else{
                    pages[key] = false;
                }
            });
            document.querySelector('body').classList.add("overflow-hidden")
            this.$refs.dashboard.classList.remove('-translate-x-full')
        },
        logout(){
            this.$store.dispatch('removeUserToken'); 
            this.$store.dispatch('emptyCart');
            this.$store.dispatch('emptyResurantName');
            this.$store.dispatch('emptyTimebelt');
            this.$router.push({ name: 'CustomerSignIn'});
        }
    },
    mounted(){
        setTimeout(() => {
            this.$refs.menu.classList.remove('-translate-x-full')
        }, 100);  
    },
}
</script>

<style>

</style>