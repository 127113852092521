<template>
    <div v-if="getSingleOrder"  class="rounded px-4" >
        <div class="grid grid-rows-1 mt-4">
            <div class="flex space-x-4 text-sm ">
                <div :class="{'bg-brand': paid}" class="h-8 w-8 bg-gray-400 text-gray-100 flex justify-center items-center rounded-full">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                </div>
                <div :class="{'p-2': !getSingleOrder.orderPaidDate }" class="text-sm" >
                    Paid
                    <div v-show="getSingleOrder.orderPaidDate" class="flex space-x-2 text-xs" >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <p> {{ (getSingleOrder.orderPaidDate) ? datewithTime(getSingleOrder.orderPaidDate) : "" }} </p>
                    </div>
                </div>
            </div>
            <div class="ml-3 mt-1 grid grid-rows-1 gap-1" >
                <div :class="{'bg-brand': paid }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': paid }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': paid }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': paid }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': paid }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': paid }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': paid }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': paid }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
            </div>
            <div class="flex space-x-4 text-sm mt-2 ">
                <div :class="{'bg-brand': confirm }" class="h-8 w-8 bg-gray-400 text-gray-100 flex justify-center items-center rounded-full">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                </div>
                <div :class="{'p-2': !getSingleOrder.orderConfirmedDate }" class="text-sm" >
                    Confirmed
                    <div  v-show="getSingleOrder.orderConfirmedDate"  class="flex space-x-2 text-xs" >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <p> {{ (getSingleOrder.orderConfirmedDate) ? datewithTime(getSingleOrder.orderConfirmedDate) : "" }}  </p>
                    </div>
                </div>
            </div>
            <div class="ml-3 -mt-2 grid grid-rows-1 gap-1" >
                <div :class="{'bg-brand': confirm }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': confirm }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': confirm }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': confirm }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': confirm }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': confirm }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': confirm }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': confirm }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
            </div>
            <div class="flex space-x-4 text-sm mt-2 ">
                <div :class="{'bg-brand':processing }" class="h-8 w-8 bg-gray-400 text-gray-100 flex justify-center items-center rounded-full">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                </div>
                <div :class="{'p-2': !getSingleOrder.orderPreparationInProgressDate }"  class="text-sm" >
                    Processing
                    <div v-show="getSingleOrder.orderPreparationInProgressDate" class="flex space-x-2 text-xs" >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <p> {{ (getSingleOrder.orderPreparationInProgressDate) ? datewithTime(getSingleOrder.orderPreparationInProgressDate) : "" }}  </p>
                    </div>
                </div>
            </div>
            <div class="ml-3 -mt-2 grid grid-rows-1 gap-1" >
                <div :class="{'bg-brand': processing }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': processing }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': processing }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': processing }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': processing }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': processing }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': processing }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': processing }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
            </div>
            <div class="flex space-x-4 text-sm mt-2 ">
                <div :class="{'bg-brand': processed }" class="h-8 w-8 bg-gray-400 text-gray-100 flex justify-center items-center rounded-full">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                </div>
                <div :class="{'p-2': !getSingleOrder.orderPreparationCompletedDate }" class="text-sm" >
                    Processed
                    <div v-show="getSingleOrder.orderPreparationCompletedDate" class="flex space-x-2 text-xs" >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <p>{{ (getSingleOrder.orderPreparationCompletedDate) ? datewithTime(getSingleOrder.orderPreparationCompletedDate) : "" }} </p>
                    </div>
                </div>
            </div>
            <div class="ml-3 -mt-2 grid grid-rows-1 gap-1" >
                <div :class="{'bg-brand': processed }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': processed }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': processed }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': processed }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': processed }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': processed }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': processed }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': processed }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
            </div>
            <div class="flex space-x-4 text-sm mt-2 ">
                <div :class="{'bg-brand':pickup }" class="h-8 w-8 bg-gray-400 text-gray-100 flex justify-center items-center rounded-full">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                </div>
                <div class="text-sm" :class="{'p-2': !getSingleOrder.orderReadyForPickupDate }" >
                    Ready for pickup
                    <div  v-show="getSingleOrder.orderReadyForPickupDate"  class="flex space-x-2 text-xs" >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <p> {{ (getSingleOrder.orderReadyForPickupDate) ? datewithTime(getSingleOrder.orderReadyForPickupDate) : "" }} </p>
                    </div>
                </div>
            </div>
            <div class="ml-3 -mt-2 grid grid-rows-1 gap-1" >
                <div :class="{'bg-brand': pickup }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': pickup }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': pickup }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': pickup }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': pickup }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': pickup }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': pickup }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': pickup }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
            </div>
                        <div class="flex space-x-4 text-sm mt-2 ">
                <div :class="{'bg-brand':assigned }" class="h-8 w-8 bg-gray-400 text-gray-100 flex justify-center items-center rounded-full">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                </div>
                <div class="text-sm" :class="{'p-2': !getSingleOrder.orderAssignedToDriverDate }">
                    Assigned to rider
                    <div v-show="getSingleOrder.orderAssignedToDriverDate" class="flex space-x-2 text-xs" >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <p> {{ (getSingleOrder.orderAssignedToDriverDate) ? datewithTime(getSingleOrder.orderAssignedToDriverDate) : "" }}</p>
                    </div>
                </div>
            </div>
            <div class="ml-3 -mt-2 grid grid-rows-1 gap-1" >
                <div :class="{'bg-brand': assigned }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': assigned }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': assigned }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': assigned }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': assigned }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': assigned }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': assigned }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': assigned }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
            </div>
             <div class="flex space-x-4 text-sm mt-2 ">
                <div :class="{'bg-brand': shipped }" class="h-8 w-8 bg-gray-400 text-gray-100 flex justify-center items-center rounded-full">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                </div>
                <div class="text-sm" :class="{'p-2': !getSingleOrder.orderShippedDate }" >
                    Shipped
                    <div v-show="getSingleOrder.orderShippedDate"  class="flex space-x-2 text-xs" >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <p> {{ (getSingleOrder.orderShippedDate) ? datewithTime(getSingleOrder.orderShippedDate) : "" }}  </p>
                    </div>
                </div>
            </div>
            <div class="ml-3 -mt-2 grid grid-rows-1 gap-1" >
                <div :class="{'bg-brand': shipped }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': shipped }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': shipped }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': shipped }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': shipped }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': shipped }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': shipped }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
                <div :class="{'bg-brand': shipped }" class="h-1 w-1 bg-gray-400 rounded-sm" ></div>
            </div>
            <div class="flex space-x-4 text-sm mt-2 ">
                <div :class="{'bg-brand': getSingleOrder.status == 'ORDER_DELIVERED' }" class="h-8 w-8 bg-gray-400 text-gray-100 flex justify-center items-center rounded-full">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                </div>
                <div class="text-sm" >
                    Delivered
                    <div v-show="getSingleOrder.orderDeliveredDate" class="flex space-x-2 text-xs" >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <p>{{ (getSingleOrder.orderDeliveredDate) ? datewithTime(getSingleOrder.orderDeliveredDate) : "" }} </p>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</template>
<script>
import moment from "moment";
export default{
    name:"track order",
    props:['singleOrder'],
    methods: {
        datewithTime(date){
            return moment(date).format('lll'); 
        }
    },
    computed:{
        getSingleOrder(){
            if(this.singleOrder){
                return this.singleOrder
            }
            else{
                return 
            }
        },
        paid(){
            if( this.getSingleOrder.status == 'ORDER_PAID' 
                || this.getSingleOrder.status == 'ORDER_CONFIRMED' 
                || this.getSingleOrder.status == 'ORDER_PREPARATION_IN_PROGRESS' 
                || this.getSingleOrder.status == 'ORDER_PREPARATION_COMPLETED' 
                || this.getSingleOrder.status == 'ORDER_ASSIGNED_TO_DRIVER' 
                || this.getSingleOrder.status == 'ORDER_READY_FOR_PICKUP'
                || this.getSingleOrder.status == 'ORDER_SHIPPED' 
                || this.getSingleOrder.status == 'ORDER_DELIVERED' ){

                    return true;
                }
        },
        confirm(){
            if(this.getSingleOrder.status == 'ORDER_CONFIRMED' 
                || this.getSingleOrder.status == 'ORDER_PREPARATION_IN_PROGRESS' 
                || this.getSingleOrder.status == 'ORDER_PREPARATION_COMPLETED' 
                || this.getSingleOrder.status == 'ORDER_ASSIGNED_TO_DRIVER' 
                || this.getSingleOrder.status == 'ORDER_READY_FOR_PICKUP'
                || this.getSingleOrder.status == 'ORDER_SHIPPED' 
                || this.getSingleOrder.status == 'ORDER_DELIVERED' ){

                    return true;
                }
        },
        processing(){
            if(this.getSingleOrder.status == 'ORDER_PREPARATION_IN_PROGRESS' 
                || this.getSingleOrder.status == 'ORDER_PREPARATION_COMPLETED' 
                || this.getSingleOrder.status == 'ORDER_ASSIGNED_TO_DRIVER' 
                || this.getSingleOrder.status == 'ORDER_READY_FOR_PICKUP'
                || this.getSingleOrder.status == 'ORDER_SHIPPED' 
                || this.getSingleOrder.status == 'ORDER_DELIVERED' ){

                    return true;
                }
        },
         processed(){
            if(this.getSingleOrder.status == 'ORDER_PREPARATION_COMPLETED' 
                || this.getSingleOrder.status == 'ORDER_ASSIGNED_TO_DRIVER' 
                || this.getSingleOrder.status == 'ORDER_READY_FOR_PICKUP'
                || this.getSingleOrder.status == 'ORDER_SHIPPED' 
                || this.getSingleOrder.status == 'ORDER_DELIVERED' ){

                    return true;
                }
        },
        assigned(){
            if(this.getSingleOrder.status == 'ORDER_ASSIGNED_TO_DRIVER' 
                || this.getSingleOrder.status == 'ORDER_SHIPPED' 
                || this.getSingleOrder.status == 'ORDER_DELIVERED' ){

                    return true;
                }
        },
         pickup(){
            if(this.getSingleOrder.status == 'ORDER_READY_FOR_PICKUP'
                || this.getSingleOrder.status == 'ORDER_ASSIGNED_TO_DRIVER'
                || this.getSingleOrder.status == 'ORDER_SHIPPED' 
                || this.getSingleOrder.status == 'ORDER_DELIVERED' ){

                    return true;
                }
        },
        shipped(){
            if(this.getSingleOrder.status == 'ORDER_SHIPPED' 
                || this.getSingleOrder.status == 'ORDER_DELIVERED' ){

                    return true;
                }
        }
    }
}

</script>