<template>
    <div class="sticky top-0 md:-top-4 z-20 bg-white pb-3" >
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Order History</h1>
            <div @click="close">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
    </div>
    <div class="">
        <div v-if="!myOrders" class="mt-4">Loading ...</div>
        <div v-else-if="myOrders != 'Nodata'"  class="grid grid-cols-1 gap-3 mt-4" >
            <div v-for="order in myOrders" :key="order" @click="changeSingle(order.orderReferenceNumber)" class="flex items-stretch border border-gray-200 hover:bg-gray-100 shadow-md hover:text-black space-x-4 py-4 pl-4 rounded">
                <div class="flex items-center" >
                    <img :src="order.restaurantImage" class="h-20 w-20 object-cover bg-purple-400 rounded-full"/>
                </div>
                <div class="text-sm relative flex-grow">
                    <div class="grid grid-cols-2 ">
                        <p>Order id</p>
                        <p class="font-semibold" >{{ order.orderReferenceNumber }}</p>
                        <p>Order Date</p>
                        <div class="" >
                            <p class="text-sm">{{ datewithTime(order.createdAt) }} </p> 
                        </div>
                        <p>Expected Date</p>
                        <div class="" >
                            <p class="text-sm">{{ shortenTime(order.cartItems[0].deliveryDate) }} </p> 
                        </div>
                        <p>Timebelt</p>
                        <div class="" >
                            <p class="text-sm">{{ order.cartItems[0].timeBelt.name }} </p> 
                        </div>
                        <p>Total</p>
                        <div class="" >
                            <p class="text-sm">N {{ formatNumber(order.totalCost) }} </p> 
                        </div>
                    </div>
                    <button @click="payOrder(order.id)" v-if="order.status == 'ORDER_PLACED'" class="bg-brand px-2 py-1 rounded mt-2" >Pay Now</button>
                    <div class="flex justify-end text-xs" >
                        <div v-if="order.status == 'ORDER_PLACED'" class="mt-4 bg-red-600 text-gray-100 font-semibold rounded-l-full px-3 py-1 top-0 right-0">Not paid</div>
                        <div v-else-if="order.status ==  'ORDER_PAID'" class="mt-4 bg-indigo-800 text-gray-100 font-semibold  rounded-l-full px-3 py-1 top-0 right-0">Paid</div>
                        <div v-else-if="order.status == 'ORDER_CONFIRMED'" class="mt-4 bg-green-600 text-gray-100 font-semibold  rounded-l-full px-3 py-1 top-0 right-0">Confirmed</div>
                        <div v-else-if="order.status == 'ORDER_READY_FOR_PICKUP'" class="mt-4 bg-yellow-600 text-gray-100 font-semibold  rounded-l-full px-3 py-1 top-0 right-0">Ready for pickup</div>
                        <div v-else-if="order.status == 'ORDER_SHIPPED'" class="mt-4 bg-blue-700 text-gray-100  font-semibold  rounded-l-full px-3 py-1 top-0 right-0">Shipped</div>
                        <div  v-else-if="order.status == 'ORDER_DELIVERED'" class="mt-4  bg-green-800 text-gray-100 font-semibold  rounded-l-full px-3 py-1 top-0 right-0">Delivered</div>
                        <div v-else-if="order.status == 'ORDER_CANCELLED'" class="mt-4 bg-gray-800 text-gray-100 font-semibold  rounded-l-full px-3 py-1 top-0 right-0">Cancelled</div>
                        <div v-else-if="order.status == 'ORDER_REFUNDED'" class="mt-4 bg-purple-800 text-gray-100 font-semibold  rounded-l-full px-3 py-1 top-0 right-0">Refunded</div>  
                        <div v-else-if="order.status == 'ORDER_REJECTED'" class="mt-4 bg-black text-gray-100 font-semibold  rounded-l-full px-3 py-1 top-0 right-0">Rejected</div>  
                        <div v-else-if="order.status == 'ORDER_PREPARATION_IN_PROGRESS'" class="mt-4 bg-blue-500 text-gray-100 font-semibold  rounded-l-full px-3 py-1 top-0 right-0">Processing</div>  
                        <div v-else-if="order.status == 'ORDER_PREPARATION_COMPLETED'" class="mt-4 bg-purple-500 text-gray-100 font-semibold  rounded-l-full px-3 py-1 top-0 right-0">Processed</div>  
                        <div v-else-if="order.status == 'ORDER_ASSIGNED_TO_DRIVER'" class="mt-4  bg-indigo-400 text-gray-100 font-semibold  rounded-l-full px-3 py-1 top-0 right-0">Assigned</div>  
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="myOrders == 'Nodata'"  class="m-h-full flex-grow flex justify-center items-center mt-16" >
            <div>
                <div class="flex justify-center">
                    <img src="@/assets/svg/order.svg" class="h-36 w-36" alt="">
                </div> 
                <p class="text-center text-sm mt-4">You haven't placed any order</p>
            </div>
        </div>
    </div>
</template>

<script>

import TrackOrder from "@/components/customers/TrackOrder.vue"
import moment from "moment";
    export default {
        name:"History",
        emits: ["close"],
        components:{
            TrackOrder
        },
        props:['token','activeuser'],
        data(){
            return{
                myOrders:'',
                singleOrder:"",
                paykey: process.env.VUE_APP_PAYSTACK_KEY,
            }
        },
        methods:{
            close(){
                this.$emit('close')
            },
            formatNumber(num) {
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            },
            async getOrderHistory(){
                let url = this.$store.state.base_url + 'order/my-orders';
                let token =  await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                if(res.data.length){
                    this.myOrders = res.data
                    this.singleOrder = this.myOrders[0]; 
                }else{
                    this.myOrders = "Nodata"
                }
 
            },
            shortenTime(date){
                return moment(date).format("MMM Do, YYYY",' h:mm:ss a'); 
            },
            datewithTime(date){
              return moment(date).format('lll');   
            },
            changeSingle(orderId){
               this.singleOrder = this.myOrders.find((item) => item.orderReferenceNumber === orderId)
            },
            async payOrder(orderId){
                let url = this.$store.state.base_url + 'payment/unpaid-order/' + orderId;
                let token =  await this.$store.getters.getToken
                let res = await this.axios.post(url,null,{
                    headers: {
                        Authorization: 'Bearer ' +  token
                    }
                })
     
                let orderID = res.data.orderReferenceNumber;
                if(res.data.amountToPayWithPaystack > 0 ){
                    let totalPaystackCost  = res.data.amountToPayWithPaystack
                    this.payWithPaystack(totalPaystackCost,orderID,res);
                }
            
            },
            payWithPaystack(amount,orderID,resbackend){
                var self = this // assign context to self variable
                const paystack = require("../../paystack/index.js")
                let paykey = this.paykey
                let paystackRes = []
                let handler = PaystackPop.setup({
                    key: paykey, 
                    email: self.activeuser.email,
                    amount: amount * 100,
                    ref: orderID, 
                    onClose: function(){
                        alert('Payment is not confirmed');
                    },
                    callback: async function(response){
                        paystackRes = response;
                        let url = self.$store.state.base_url + 'payment/paystack';
                        
                        let data = {
                            "orderId": resbackend.data.orderId,
                            "reference": paystackRes.reference,
                            "trans": paystackRes.trans,
                            "trxref": paystackRes.trxref
                        }
                        let token =  await self.$store.getters.getToken
                        let res = await self.axios.post(url,data,{
                            headers: {
                                Authorization: 'Bearer ' +  token
                            }
                        })

                        self.myOrders.map((item)=> {
                            if(item.id == resbackend.data.orderId ){
                                item.status = 'ORDER_PAID'
                            }
                        })

                    }
                });
                handler.openIframe();
            },
        },
        created(){
            this.getOrderHistory();
        }
    }

</script>