<template>
    <div class="flex justify-between">
        <h1 class="text-xl font-semibold">Fund wallet</h1>
        <div @click="close">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </div>
    </div>
     <p class="mt-10" >Current Balance : NGN {{formatNumber(activeuser.walletBalance) }}</p> 
     <form @submit.prevent="fundWallet" class="mt-4">
        <div v-if="error" class="text-sm text-red-500 my-4" >{{ error}}</div>
        <div v-if="success" class="text-sm text-green-500 my-4" >{{ success }}</div>
        <label>Enter amount so as to fund your wallet</label>       
        <div class="flex space-x-2">
            <input type="number" class="input" v-model="amount" placeholder="Amount"> 
        </div>
        <button class="mt-2 px-3 bg-brand rounded text-sm p-3 font-semibold">Fund my wallet</button>
    </form>
</template>
<script>

const paystack = require("../../paystack/index.js")
export default {
    name:"Wallet",
    emits: ["close","updateWallet"],
    props:['activeuser','token','balance'],
    data(){
        return{
            amount:"",
            error:null,
            success:null,
            paykey: process.env.VUE_APP_PAYSTACK_KEY,
        }
    },
    computed: {
        reference() {
            let text = "";
            let possible =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (let i = 0; i < 8; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            return text;
        }
    },
    methods:{
        close(){
            this.$emit('close')
        },
        fundWallet(){
            if(!this.amount){
                this.error = "Amount field is required"
            }else if(this.amount < 100){
                this.error = "Minimum deposit is NGN 100"
            }else{
                let email = this.activeuser.email
                let ref = 'NW-' + this.reference
                let paykey = this.paykey
                let amount = this.amount
                this.payWithPaystack(email,ref,paykey,amount)
            }
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        payWithPaystack(email,ref,paykey,amount){
             let self = this
             let handler = PaystackPop.setup({
                key: paykey, 
                email:email,
                amount: amount * 100,
                ref: ref, 
                onClose: function(){
                },
                callback: function(response){
                    let paystackRes = response;
                    let url = self.$store.state.base_url + `wallet/funding/verify/${ref}`;
                    // let data = {
                    //     "amountPaid": parseInt(amount),
                    //     "trxref": paystackRes.trxref,
                    //     "userId": self.activeuser.userId
                    // }
                    let data = null;
                    this.axios.post(url,data,{
                        headers: {
                            Authorization: 'Bearer ' +  self.token.access_token
                        }
                    })
                    .then((res) =>{
                        self.$emit('updateWallet')
                    })
                    
                }
            });
            handler.openIframe();
        }
    }
}

</script>