<template>
    <div class="flex justify-between">
        <h1 class="text-xl font-semibold">Reviews</h1>
        <div @click="close">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </div>
    </div>
    <div class="m-h-full flex-grow flex justify-center items-center mt-16" >
        <div>
            <img src="@/assets/svg/good-review.svg" class="h-52 w-52 text-gray-200" alt="">
            <p class="text-center text-sm">You haven't reviewed any vendor yet</p>
        </div>
    </div>
</template>
<script>
export default {
    name:"Reviews",
    emits: ["close"],
    methods:{
        close(){
            this.$emit('close')
        }
    }
}
</script>