<script>
import moment from 'moment';
export default {
    name:"SearchFood",
    props:['restaurant'],
    components:{

    },
    data(){
        return{
            search:"",
            result:[],
            showDropdown:false,
            showCartToast:false
        }
    },
    methods:{
        async getSearch(){
            let url = this.$store.state.base_url + 'restaurant/'+  this.$store.state.storeDetails.restaurantId  +'/food';
            try{
                let res = await this.axios.get(url,{
                    params:{
                        page:0,
                        size: 10,
                        foodName:this.search
                    }
                });
                this.result = res.data.content.filter((item) => item.name.toLowerCase().includes(this.search.toLowerCase()) )
                this.showDropdown = true

            }catch(error){

            }
        },
        showCartToastMessage(message){
            if(this.cartMessage){
                this.showCartToast = false
            }
            this.cartMessage = message;
            this.toogleCartToast()
            setTimeout(()=>{
                this.cartMessage = "";
                this.showCartToast = false;
            },1000)
        },
        toogleCartToast(){
            this.showCartToast = !this.showCartToast
        },
        addToCart(data){
            if(this.restaurant.isOpen){
                let timeBelt =  this.$store.getters.getTimebelt;
                let deliveryDate = moment(new Date(this.$store.getters.getDate)).format();
                let foodName = `${data.name}${(data.unitOfMeasure) ? `/${data.unitOfMeasure.toLowerCase()}` : ""}`
                let item = {
                    foodId : data.foodId,
                    foodName: foodName,
                    quantity : 1,
                    amount: data.price,
                    foodImage: data.images[0].image,
                    timeBeltId: timeBelt.id,
                    expectedDeliveryDate:deliveryDate, 
                    restaurantId: this.$store.state.storeDetails.restaurantId,
                    deliveryAddress: this.$store.getters.getAddress.address,
                    foodType: data.foodType
                };
                const result = data.timeBelts.find( ({ name }) => name === timeBelt.name );
                if (typeof result !== 'undefined' || !timeBelt) {
                    let cart = this.$store.getters.getCart;
                    if(!cart.cartItems.length){
                        cart.cartItems.push(item)
                        this.showCartToastMessage(`Added ${foodName} to cart`)
                        this.$store.dispatch('addCart',cart)
                    }else{
                        let result = cart.cartItems.find(({ foodId }) => foodId === item.foodId );
                        if(result){
                            cart.cartItems.map((elem) => { 
                                if (elem.foodId === item.foodId) {
                                    elem.quantity = elem.quantity + item.quantity
                                }  
                            });
                            this.showCartToastMessage(`Added ${foodName} to cart`)
                            this.$store.dispatch('addCart',cart);
                        }else{       
                            cart.cartItems.push(item);
                            this.showCartToastMessage(`Added ${foodName} to cart`)
                            this.$store.dispatch('addCart',cart);
                        }
                    }
                    //Update count array 
                    let count = this.$store.getters.getCart.cartItems.length + this.$store.getters.getCart.containers.length
                    this.$store.dispatch('addCountcart',count);
                }else{
                    this.error = true
                }
                this.Quantity = 1;
            }
        },
        closeDropdown(){
            setTimeout(()=>{
                this.showDropdown = false;
            },500);
        },
        showDropResult(){
            this.showDropdown = true;
        }
    }
}
</script>

<template>
    <div class="relative">
        <div class="md:bg-white bg-gray-100 lg:w-500 w-full px-4 py-1 flex gap-3 items-center  text-sm rounded-full">
            <div class="">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
            </div>
            <div class="flex-grow">
                <input v-model="search" @click="showDropResult" @focus="showDropResult" @change="getSearch"  @input="getSearch" type="text" class="w-full md:bg-white bg-gray-100 py-2 outline-none" placeholder="Search food">
            </div>
        </div>

        <div v-if="showDropdown && result.length" @mouseleave="closeDropdown" :class="{ 'h-96': result.length > 5  }" class="bg-white absolute w-full p-3 overflow-y-auto shadow mt-1 rounded">
            <ul class="flex flex-col gap-3">
                <li v-for="meal in result" :key="meal" @click="addToCart(meal)" class="flex cursor-pointer items-center justify-between">
                    <div class="flex items-center gap-3 text-sm" >
                        <div>
                            <img :src="meal.images[0].image" class="h-12 w-12 object-cover rounded-md" alt="">
                        </div>
                        <div>   
                            <p>{{ meal.name  }}</p>
                            <p class="text-xs">&#8358;{{ meal.price  }}</p>
                        </div>
                    </div>
                    <div v-if="restaurant.isOpen">
                        <button @click="addToCart(meal)">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div v-if="showCartToast" class="fixed lg:top-24 lg:right-8 top-24 right-4">
        <div v-if="cartMessage" class="bg-green-400 flex gap-3 animate__animated animate__bounceIn text-sm text-gray-100 px-4 py-2 rounded-full">
            <div>{{ cartMessage }}</div>
            <button @click="toogleCartToast">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>                  
            </button>
        </div>
    </div>
   
</template>

