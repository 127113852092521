<template>
    <div @mouseleave="closeDropdown" class="bg-white shadow-2xl z-50 w-52 absolute right-2 mt-8 rounded">
        <div class="bg-white h-5 w-5 transform rotate-45 absolute -top-2 right-4 mr-1"></div>
        <div class="flex p-4">
            <div class="w-2/3 p-3 h-500 hidden overflow-y-auto">
                <OrderHistory v-if="pages.history" :activeuser="activeuser"  :token="userDetails" @close="closeDropdown" />
                <Reviews v-if="pages.review" :token="userDetails"  @close="closeDropdown"/>
                <Settings v-if="pages.setting" :token="userDetails" :activeuser="activeuser" @update="updateCustomer"  @close="closeDropdown" />
                <Wallet  v-if="pages.wallet" :activeuser="activeuser"  :token="userDetails" @updateWallet="updateCustomer"  @close="closeDropdown"/>
            </div>
            <div class="flex-grow items-stretch text-sm  w-2/5" >
                <div class="grid grid-rows-1 gap-3"> 
                    <div class="justify-between hidden border-b pb-3 border-gray-200" >
                        <h1 class="font-semibold" >Wallet</h1>
                        <p class="font-semibold">&#8358; {{ (activeuser.walletBalance) ? formatNumber(activeuser.walletBalance) :  '0.00' }} </p>
                    </div>
                    <div class="flex justify-between border-b pb-3 border-gray-200" >
                        <div>
                            <router-link :to="{ name: 'OrderHistory'}" :class="{ 'text-brand': pages.history}" class=" font-semibold hover:text-brand transition ease-in-out duration-300" >Order history</router-link>
                            <p class="text-xs text-gray-500">View your order history</p>
                        </div>
                    </div>
                    <div class="flex justify-between border-b pb-3 border-gray-200" >
                        <div>
                            <router-link :to="{ name: 'Resturants'}" :class="{ 'text-brand': this.$route.name == 'Resturants' }" class=" font-semibold hover:text-brand transition ease-in-out duration-300" >Restaurants</router-link>
                            <p class="text-xs text-gray-500">Place an order</p>
                        </div>
                    </div>
                    <div class="flex justify-between border-b pb-3 border-gray-200" >
                        <div>
                             <router-link :to="{ name: 'WalletHistory'}" :class="{ 'text-brand': pages.setting}" class="font-semibold hover:text-brand transition ease-in-out duration-300" >Wallet</router-link>
                            <p class="text-xs text-gray-500">Add money into your wallet</p>
                        </div>
                    </div>
                    <div class="flex justify-between border-b pb-3 border-gray-200" >
                        <div>
                            <router-link :to="{ name: 'Settings'}" :class="{ 'text-brand': pages.setting}" class="font-semibold hover:text-brand transition ease-in-out duration-300" >Account Settings</router-link>
                            <p class="text-xs text-gray-500">Edit your profile</p>
                        </div>
                    </div>
                    <div class="flex justify-between " >
                        <button @click="logout" class="font-semibold hover:text-brand transition ease-in-out duration-300" >Logout</button>
                    </div>
                </div>  
            </div>
        </div>
    </div>
    <!--<div @click.self="closeDropdown" class="fixed top-0 left-0 h-full w-full -z-10" ></div>-->
</template>
<script>

import OrderHistory from "@/components/customers/OrderHistory"
import Reviews from "@/components/customers/Reviews"
import Settings from "@/components/customers/Settings"
import Wallet from "@/components/customers/Wallet"
export default {
    name:'BigDropdown',
    emits: ["closeDropdwn","updateCustomer"],
    props:['activeuser','userDetails'],
    components:{
        OrderHistory,Reviews,Settings,Wallet
    },
    data(){
        return{
            showeditProfile:false,
            pages:{
                wallet:false,
                setting:false,
                history:false,
                review:false
            }
        }
    },
    methods:{
        Changepage(to){
            let pages = this.pages
            Object.keys(pages).map(function(key, index) {
                 if(key == to){
                    pages[key] = true;
                }else{
                    pages[key] = false;
                }
            });
        },
        updateCustomer(){
            this.$emit('updateCustomer')
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        closeDropdown(){
            this.$emit('closeDropdwn')
        },
        toogleeditProfile(){
            this.showeditProfile = !this.showeditProfile;
        },
        toogleDeliveryAddress(){
            this.ChangeAddress = !this.ChangeAddress
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.address = addressData;
            this.address.address = (addressData.street_number) ?`${addressData.street_number}, ${addressData.route}, ${addressData.locality}, ${addressData.country}`:`${addressData.route} ${addressData.locality}, ${addressData.country}`
            this.deliveryAddress = this.address.address
            this.$refs.address.update(this.deliveryAddress);
        },
        submitAddress(){
            this.toogleDeliveryAddress()
            this.address
        },
        logout(){
            this.$store.dispatch('removeUserToken'); 
            this.$store.dispatch('emptyCart');
            this.$store.dispatch('emptyResurantName');
            this.$store.dispatch('emptyTimebelt');
            this.$router.push({ name: 'CustomerSignIn'});
        }
    }
}


</script>